import anime from "animejs";
const CommonMenu = () => {

	const _commonMenu = (type, isTransition) => new Promise((resolve) => {
		const time = 1000;
		const ease = "cubicBezier(.215,.61,.355,1)";
		const headerMenu = document.querySelector(".header__content");
		if (document.querySelector(".header__menu")) {
			if (type === "enter") {
				const tl = anime.timeline({
					autoplay: true
				});
				headerMenu.classList.add("header__content--opened");
				if (document.querySelector(".header--light")) {
					headerMenu.classList.add("header__content--light");
				}
				anime.remove([".header__menu ul li", ".menu-bg", ".header__lang-menu"]);
				tl.add({
					targets: (".menu-bg"),
					scaleY: [0, 1],
					transformOrigin: ["0px 100%", "0px 100%"],
					easing: ease,
					duration: time
				});
				tl.add({
					targets: document.querySelectorAll(".header__menu ul li, .header__lang-menu"),
					translateY: [70, 0],
					opacity: [0, 1],
					delay: anime.stagger(100),
					easing: ease,
					duration: time * 0.8
				}, time * 0.2);
			}
			if (type === "exit") {
				const tl = anime.timeline({
					autoplay: true
				});
				anime.remove([".header__menu ul li", ".menu-bg", ".header__lang-menu"]);

				tl.add({
					targets: document.querySelectorAll(".header__menu ul li, .header__lang-menu"),
					// translateY: [0, -70],
					opacity: [1, 0],
					easing: ease,
					// delay: anime.stagger(100),
					duration: time * 0.5,
					begin: () => {
						if (document.querySelector(".header--light")) {
							headerMenu.classList.remove("header__content--light");
						}
					}
				}, 0);
				if (!isTransition) {
					tl.add({
						targets: (".menu-bg"),
						transformOrigin: ["0px 0%", "0px 0%"],
						scaleY: [1, 0],
						easing: ease,
						duration: time,
						complete: () => {
							// document.querySelector("[data-js-hm-menu]").disabled = false;

							headerMenu.classList.remove("header__content--opened");
							document.body.classList.remove("menu--opened");
							resolve();
						}
					}, 0);
				}
			}
		}

	});

	return {
		init: _commonMenu
	};
};

export default CommonMenu;