import CommonMenu from './animations';

const HamburgerMenu = () => {

	const _toggleMenu = () => {
		const commonMenu = CommonMenu();
		const btnMenu = document.querySelector('[data-js-hm-menu]');

		btnMenu.addEventListener('click', function () {
			this.classList.toggle('burger-btn--active');
			if (this.classList.contains('burger-btn--active')) {
				// headerMenu.classList.add("header__content--opened");
				document.querySelector('body').classList.add('no-scroll');
				document.querySelector('html').classList.add('no-scroll');
				commonMenu.init('enter');
				// Dynamic import
				// import(/* webpackChunkName: "toggleMenu" */ "./animations").then(module => {
				// 	module.CommonMenu("enter")
				// });
			} else {
				// headerMenu.classList.remove("header__content--opened");
				document.querySelector('body').classList.remove('no-scroll');
				document.querySelector('html').classList.remove('no-scroll');
				commonMenu.init('exit');
				// Dynamic import
				// import(/* webpackChunkName: "toggleMenu" */ "./animations").then(module => {
				// 	module.CommonMenu("exit")
				// });
			}

		});
	};

	return {
		init: _toggleMenu
	};
};

export default HamburgerMenu;