import { actions, mapActions, store } from '@/store';
import anime from 'animejs';

const mappedActions = mapActions(actions, store);

export default (targets, step) => {
	// const init = Init();
	// const commonMenu = CommonMenu();
	const headerMenu = document.querySelector('.header__content');
	const btnBurger = document.querySelector('.burger-btn');
	let duration = 500;
	targets.style.display = step === 'leave' ? '' : 'none'

	let easing;
	if (step === 'leave') {
		easing = 'easeOutSine'
	}
	if (step === 'enter') {
		easing = 'cubicBezier(0.43, 0.195, 0.02, 1)'
	}
	const anim = anime.timeline({
		easing: easing,
		duration,
	});

	if (step === 'leave') {
		if (!headerMenu.classList.contains('header__content--opened')) {
			anim.add({
				targets,
				opacity: [1, 0],
				complete: () => {
					targets.style.display = 'none';
				}
			}, 0);
		} else {
			// commonMenu.init('exit', true).then(() => {
			// 	btnBurger.classList.remove('burger-btn--active');
			// });

			anim.add({
				targets,
				opacity: [1, 0],
				complete: () => {
					targets.style.display = 'none';
				}
			}, 0);
		}
	}
	if (step === 'enter') {
		// init.beforeEnter(targets);
		easing = 'easeOutSine';
		duration = 500;

		let timeout;
		anim.add({
			targets: targets,
			opacity: [0, 1],
			easing: easing,
			duration: duration,
			begin: () => {
				targets.style.display = 'block';
				// init.enter(targets);
				window.scrollTo(0, 0);
			},
			complete: () => {
				if (store.getState().isFirstLoad) {
					mappedActions.setInitScrollbar(true)
					mappedActions.setIsReady(true)
				}
			}
		}, duration)

		clearTimeout(timeout)
		if (!store.getState().isFirstLoad) {
			// if (targets.dataset.barbaNamespace === 'home') {
			// 	animationsHome.init(targets, anim, duration, easing);
			// } else {
			// 	timeout = setTimeout(() => {
			// 		document.querySelector('.header-menu').style.opacity = 1;
			// 	}, duration * 2)
			// }
		} else {
			if (targets.dataset.barbaNamespace !== 'home') {
				timeout = setTimeout(() => {
					// document.querySelector('.header-menu').style.opacity = 1;
				}, duration)
				
			}
			
		}
	}

	return anim.finished;
}